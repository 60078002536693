<template>
  <div class="detail">
    <div class="detail-header">
      <div class="detail-header-text">明细</div>
      <div style="padding: 5px" @click="closeClick">
        <i class="el-icon-close icon"></i>
      </div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>优惠券明细</el-breadcrumb-item>
      </el-breadcrumb>

      <el-button type="success" style="height: 36px" @click="reload"
        ><i style="font-size: 18px" class="el-icon-refresh-right"></i
      ></el-button>
    </div>
    <div class="detail-search">
      <span style="font-size: 14px">领取日期：</span>
      <el-date-picker
        v-model="date1"
        align="right"
        type="date"
        placeholder="选择日期"
        style="width: 12% !important"
      >
      </el-date-picker>
      <span style="margin: 0 5px">-</span>
      <el-date-picker
        v-model="date2"
        align="right"
        type="date"
        placeholder="选择日期"
        style="width: 12% !important"
      >
      </el-date-picker>
      <span style="font-size: 14px">是否使用:</span>
      <el-select v-model="value" style="width: 8% !important; margin: 0 10px">
        <el-option
          v-for="(item, index) in options"
          :key="item"
          :label="item"
          :value="index"
        >
        </el-option>
      </el-select>
      <span style="font-size: 14px">是否过期:</span>
      <el-select v-model="overdue" style="width: 8% !important; margin: 0 10px">
        <el-option
          v-for="(item, index) in overdues"
          :key="item"
          :label="item"
          :value="index"
        >
        </el-option>
      </el-select>
      <el-input
        placeholder="请输入用户名称或联系电话"
        style="width: 20%; margin-right: 10px"
        v-model="searchInp"
      ></el-input>
      <el-button type="success" @click="searchBtn"
        ><i class="el-icon-search"></i>查询</el-button
      >
    </div>
    <div class="detail-tab">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="draw_time" label="领取时间" :resizable="false">
        </el-table-column>
        <el-table-column
          prop="member_name"
          label="会员名称"
          width="180"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column prop="bind_phone" label="手机号码" :resizable="false">
        </el-table-column>
        <el-table-column prop="is_employ" label="是否使用" :resizable="false">
          <template slot-scope="scope">
            {{ scope.row.is_employ == 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="expiration_time"
          label="过期时间"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_number"
          label="数量"
          width="70"
          :resizable="false"
        >
        </el-table-column>
      </el-table>
      <table class="detail-table">
        <tr>
          <td class="detail-total">未使用券合计</td>
          <td class="detail-num">{{ unused }}</td>
        </tr>
      </table>
    </div>
    <div class="detail-bottom">
      <div class="detail-bottom-left">
        显示第 {{ start }} 至 {{ end }} 项结果，共 {{ tableDataLength }} 项
      </div>
      <div class="detail-bottom-right">
        <div class="right-btn" @click="homeClick">首页</div>
        <div class="right-btn" @click="upClick">上页</div>
        <el-pagination
          background
          layout="pager"
          :total="tableDataLength"
          :current-page="page"
          :page-size="length"
          :pager-count="5"
          :hide-on-single-page="true"
          @current-change="currentChange"
        ></el-pagination>
        <div class="right-btn" @click="nextClick">下页</div>
        <div class="right-btn" @click="endClick">末页</div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateYear } from "./utilse";
export default {
  props: ["id"],
  data() {
    return {
      date1: "",
      date2: "",
      value: "",
      options: ["否", "是"],
      overdue: "",
      overdues: ["否", "是"],
      searchInp: "",
      tableData: [],
      page: 1,
      length: 10,
      tableDataLength: 0,
      unused: 0,
      start: 1,
      end: 10,
    };
  },
  mounted() {
    this.getTabList();
  },
  watch: {
    page: {
      handler() {
        if (this.page == 1) {
          this.start = 1;
          this.end = this.tableDataLength < 10 ? this.tableDataLength : 10;
        } else {
          this.start = (this.page - 1) * this.length + 1;
          this.end =
            this.page * this.length > this.tableDataLength
              ? this.tableDataLength
              : this.page * this.length;
        }
      },
    },
  },
  computed: {},
  methods: {
    reload() {
      this.getTabList();
    },
    closeClick() {
      this.$emit("detailCloseClick");
    },
    getTabList() {
      this.$http
        .post("/coupon/lstBonds", {
          currentPage: this.page,
          currentLength: this.length,
          coupon_id: this.$props.id,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.tableDataLength = res.data.count;
          this.unused = res.data.unEmploy;
        });
    },
    currentChange(page) {
      this.page = page;
      this.getTabList();
    },
    homeClick() {
      this.page = 1;
      this.getTabList();
    },
    upClick() {
      this.page = --this.page;
      this.getTabList();
    },
    nextClick() {
      this.page = ++this.page;
      this.getTabList();
    },
    endClick() {
      this.page = Math.ceil(this.tableDataLength / this.length);
      this.getTabList();
    },
    searchBtn() {
      this.$http
        .post("/coupon/bondsSearch", {
          search: this.searchInp,
          firstTime: dateYear(this.date1),
          lastTime: dateYear(this.date2),
          is_employ: this.value,
          expiration: this.overdue,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableDataLength = res.data.count;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.detail {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    &-text {
      font-size: 12px;
    }
  }
  .breadcrumb {
    height: 40px;
    line-height: 40px;
    box-sizing: content-box;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-search {
    padding: 10px 20px;
  }
  &-tab {
    padding: 10px 20px;
  }
  &-table {
    display: flex;
    border: 1px solid #dddddd;
    border-top: none;
    font-size: 12px;
    tr {
      display: flex;
      width: 100%;
      .detail-total {
        padding: 10px 0;
        flex: 1;
        text-align: right;
        padding-right: 10px;
      }
      .detail-num {
        padding: 10px 0;
        width: 70px;
        text-align: center;
        border-left: 1px solid #ddd;
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    &-left {
      font-size: 14px;
      color: #666666;
    }
    &-right {
      display: flex;
      align-items: center;
      .right-btn {
        padding: 3px 8px;
        border: 1px solid #cbcbcb;
        font-size: 14px;
        color: #666666;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
