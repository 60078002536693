<template>
  <div>
    <div v-if="addActiveDisable && !detailShow">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>优惠券</el-breadcrumb-item>
          <el-breadcrumb-item>{{ mybreadcrumb }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="padding: 5px">
          <i class="el-icon-close icon" @click="closeClick"></i>
        </div>
      </div>
      <div class="tableInput">
        <span>日期范围：</span>
        <el-date-picker
          v-model="date1"
          align="right"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions1"
          style="width: 12% !important"
        >
        </el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker
          v-model="date2"
          align="right"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions2"
          style="width: 12% !important"
        >
        </el-date-picker>
        <el-select
          v-model="value"
          @change="optionClick(value)"
          style="width: 8% !important; margin: 0 10px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="success" @click="searchBtn"
          ><i class="el-icon-search"></i>查询</el-button
        >
      </div>
      <div class="tableBtn">
        <el-button type="primary" @click="addActiveDisable = !addActiveDisable"
          ><i class="el-icon-plus"></i> 新增优惠券</el-button
        >
        <el-button type="danger" style="margin-left: 10px" @click="batchClick"
          ><i class="el-icon-delete"></i> 批量删除</el-button
        >
      </div>

      <div class="table">
        <!-- 表格 -->
        <div style="padding: 10px 0">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column
              prop="id"
              sortable
              label="ID"
              align="center"
              width="80%"
            >
            </el-table-column>
            <el-table-column
              prop="created_at"
              sortable
              label="创建日期"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="coupon_name" label="名称" align="center">
            </el-table-column>
            <el-table-column label="类型" align="center">
              {{ type }}
            </el-table-column>
            <el-table-column
              prop="card_satrt_date"
              sortable
              label="使用起始日期"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="card_end_date"
              sortable
              label="使用结束日期"
              align="center"
            >
            </el-table-column>
            <el-table-column sortable label="使用门槛" align="center">
              <template slot-scope="scope">
                {{ scope.row.usabl_doorsill ? scope.row.usabl_doorsill : 0 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="residue_number"
              sortable
              label="剩余张数"
              align="center"
            >
            </el-table-column>
            <el-table-column label="管理" align="center">
              <template slot-scope="scope">
                <div class="admin">
                  <el-button type="primary" @click="putBtn(scope.row)"
                    >投放</el-button
                  >
                  <el-button type="primary" @click="toDetail(scope.row)"
                    >明细</el-button
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i
                  class="el-icon-edit poiner"
                  @click="handleEdit(scope.row)"
                ></i>
                <span style="margin: 0 10px"></span>
                <i
                  class="el-icon-delete poiner"
                  @click="handleDelete(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span style=""
              >显示第&nbsp;{{ start }}&nbsp;至&nbsp;{{
                end
              }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
            >

            <div class="detail-bottom-right">
              <div class="right-btn" @click="homeClick">首页</div>
              <div class="right-btn" @click="upClick">上页</div>
              <el-pagination
                background
                layout="pager"
                :total="tableFinshNum"
                :current-page="page"
                :page-size="length"
                :pager-count="5"
                @current-change="currentChange"
              ></el-pagination>
              <div class="right-btn" @click="nextClick">下页</div>
              <div class="right-btn" @click="endClick">末页</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="!addActiveDisable && !detailShow">
        <add-active
          @close="closeAdd"
          :breadcrumb="mybreadcrumb"
          :data="data"
        ></add-active>
      </div>
      <Detail v-if="detailShow" @detailCloseClick="detailCloseClick" :id="id" />
    </transition>
    <el-dialog :visible.sync="centerDialogVisible" width="30%">
      <span slot="title" style="font-size: 12px">首页>优惠券>投放</span>
      <el-form :model="ruleForm" :rules="rules">
        <el-form-item label="指定会员:" prop="name">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="目标会员:" prop="name">
          <el-radio-group v-model="ruleForm.member_object">
            <el-radio
              :label="index"
              v-for="(item, index) in objs"
              :key="item"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="会员状态:" prop="status">
          <el-select v-model="ruleForm.status" style="width: 100% !important">
            <el-option
              v-for="(item, index) in status"
              :key="item"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量:" prop="number">
          <el-input v-model="ruleForm.number" type="number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="submitClick">发货</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { urlList } from "./child/data";
import Detail from "./child/detail.vue";
import { dateYear } from "./child/utilse";
export default {
  props: ["breadcrumb"],
  components: {
    addActive: () => import("./child/discountCoupon_add.vue"),
    Detail,
  },
  data() {
    return {
      date1: "",
      date2: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      ruleForm: {
        phone: "",
        member_object: "",
        status: "",
        number: "",
      },
      rules: {
        number: [{ required: true, message: "请输入投放数量" }],
      },
      objs: ["VIP0", "VIP1", "VIP2", "VIP3", "VIP4", "VIP5"],
      status: ["激活会员", "所有会员", "未激活会员"],
      value: "状态",
      options: [
        {
          value: "待发布",
          label: "待发布",
        },
        {
          value: "进行中",
          label: "进行中",
        },
        {
          value: "已关闭",
          label: "已关闭",
        },
      ],

      tableData: [],
      isResult: false,
      tableFinshNum: 0,
      addActiveDisable: true,
      couponType: "",
      page: 1,
      length: 10,
      type: "",
      centerDialogVisible: false,
      detailShow: false,
      id: "",
      data: "",
      batch: [],
      start: 1,
      end: 10,
    };
  },
  async mounted() {
    urlList.forEach((item) => {
      if (item.title == this.$props.breadcrumb) {
        this.couponType = item.coupon_type;
        this.type = item.type;
      }
    });
    await this.getTabList();
    this.end = this.tableFinshNum > 10 ? 10 : this.tableFinshNum;
  },
  computed: {
    mybreadcrumb() {
      return this.breadcrumb;
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
        this.start =
          this.tableData.length == 0 ? 0 : (this.page - 1) * this.length + 1;
        this.end = (this.page - 1) * this.length + this.tableData.length;
      },
    },
  },

  methods: {
    async closeAdd(data) {
      this.addActiveDisable = data;
      await this.getTabList();
      this.data = "";
      this.end = this.tableFinshNum > 10 ? 10 : this.tableFinshNum;
    },
    getTabList() {
      return this.$http
        .post("/coupon/lst", {
          currentPage: this.page,
          currentLength: this.length,
          coupon_type: this.couponType,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    optionClick(data) {
      console.log(data);
    },

    searchBtn() {
      this.$http
        .post("/coupon/search", {
          firstTime: dateYear(this.date1),
          lastTime: dateYear(this.date2),
          currentPage: this.page,
          currentPageTotal: this.length,
          coupon_type: this.couponType,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleEdit(row) {
      this.data = row;
      this.addActiveDisable = false;
    },
    async handleDelete(row) {
      try {
        await this.$confirm("确认删除这条记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        const res = await this.$http.post("/coupon/del", {
          id: row.id,
          coupon_type: this.couponType,
        });
        if (res.data.status == "success") {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          //123
          await this.getTabList();
          this.end = this.tableFinshNum > 10 ? 10 : this.tableFinshNum;
        }
      } catch (error) {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      }
    },
    handleSelectionChange(data) {
      this.batch = data;
    },
    currentChange(page) {
      this.page = page;
    },
    homeClick() {
      if (this.page == 1) return;
      this.page = 1;
    },
    upClick() {
      if (this.page == 1) return;
      this.page = --this.page;
    },
    nextClick() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page = ++this.page;
    },
    endClick() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
    async batchClick() {
      if (this.batch.length > 0) {
        try {
          await this.$confirm("确认删除这条记录?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          const arr = [];
          this.batch.forEach((item) => {
            arr.push(item.id);
          });
          const res = await this.$http.post("/coupon/bacthdel", {
            idArr: arr,
            coupon_type: this.couponType,
          });
          if (res.data.status == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            await this.getTabList();
            this.end = this.tableFinshNum > 10 ? 10 : this.tableFinshNum;
          }
        } catch (error) {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        }
      }
    },
    closeClick() {
      this.$emit("closeClick");
    },
    putBtn(row) {
      this.centerDialogVisible = true;
      this.id = row.id;
    },
    detailCloseClick() {
      this.detailShow = false;
    },
    toDetail(row) {
      this.detailShow = true;
      this.id = row.id;
    },
    submitClick() {
      this.$http
        .post("/coupon/issueBonds", {
          phone: this.ruleForm.phone,
          member_object: this.ruleForm.member_object,
          status: this.ruleForm.status,
          number: Number(this.ruleForm.number),
          coupon_id: this.id,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.ruleForm.phone = "";
            this.ruleForm.member_object = "";
            this.ruleForm.status = "";
            this.ruleForm.number = "";
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}
.detail-bottom {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  &-left {
    font-size: 14px;
    color: #666666;
  }
  &-right {
    display: flex;
    align-items: center;
    .right-btn {
      padding: 3px 8px;
      border: 1px solid #cbcbcb;
      font-size: 14px;
      color: #666666;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.tableBtn {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.tableStatus {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 3px;
  font-weight: bold;
}
</style>
<style lang="less" scoped>
.el-select {
  margin: 0 5px !important;
  width: 8% !important;
}
.el-button + .el-button {
  margin-left: 0;
}
.el-radio {
  margin-right: 10px;
}
</style>
